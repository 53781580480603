<template>
  <b-modal id="modal-teacher-college" hide-footer>
    <template #modal-header>
      <h4>
        {{
          typeName === 0
            ? $t("courses.add-teacher")
            : $t("courses.add-students")
        }}
      </h4>
      <b-button
        v-if="type === false && typeName !== 0"
        @click="type = !type"
        size="sm"
        variant="link"
        class="ml-auto"
      >
        {{ $t("upload.upload") }}
      </b-button>
      <b-button
        v-if="type == true"
        @click="type = !type"
        size="sm"
        variant="link"
        class="ml-auto"
      >
        {{
          typeName === 0
            ? $t("courses.add-teacher")
            : $t("courses.add-students")
        }}
      </b-button>
    </template>
    <FormUser
      v-if="type === false"
      :type="typeName"
      @close="$bvModal.hide('modal-teacher-college')"
    />
    <!-- <MassiveLoadTeacher
      v-if="type === true && typeName === 0"
      @close="$bvModal.hide('modal-teacher-college')"
    /> -->
    <MassiveLoadStudent
      v-if="type === true && typeName !== 0"
      :type="typeName"
      @close="$bvModal.hide('modal-teacher-college')"
    />
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import FormUser from "./Components/AddUsers.vue";
import MassiveLoadTeacher from "./Components/AddLoadTeacher.vue";
import MassiveLoadStudent from "./Components/AddLoadStudent.vue";

export default {
  name: "modalForm",
  components: {
    FormUser,
    MassiveLoadTeacher,
    MassiveLoadStudent,
  },
  mounted() {
    core.index();
  },
  data() {
    return {
      type: false,
      typeName: 0,
    };
  },
  methods: {
    ...mapActions({
      addUserCourses: "addUserCourses",
      deleteUserCourses: "deleteUserCourses",
    }),
    async show(item) {
      this.typeName = item.type;
      this.$bvModal.show("modal-teacher-college");
    },
    async onSubmit() {
      let payload = {
        id: this.data.id,
        user_id: this.data.user_id.id,
      };
      const resp = await this.addUserCourses({
        course: this.$route.params.id,
        data: payload,
      });
      this.$bvModal.hide("modal-teacher-college");
      if (resp.status === 201 || resp.status === 200) {
        core.showSnackbar(
          "success",
          !this.data.id
            ? this.$t("app.success-create")
            : this.$t("app.success-edit")
        );
      }
      this.reserForm();
    },
    reserForm() {
      this.data = {
        id: null,
        user_id: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      coursesLoading: "coursesLoading",
    }),
  },
};
</script>
