<template>
  <div class="">
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form-group class="ml-3" :label="$t('upload.type-upload')">
        <b-form-radio
          v-model="type"
          name="some-radios"
          :value="uploadTeacherOption"
          >{{ $t("upload.upload-teacher") }}</b-form-radio
        >
        <b-form-radio
          v-model="type"
          name="some-radios"
          :value="uploadAttorneyOption"
          >{{ $t("upload.upload-attorneys") }}</b-form-radio
        >
        <b-form-radio
          v-model="type"
          name="some-radios"
          :value="uploadStudentOption"
          >{{ $t("upload.upload-students") }}</b-form-radio
        >
      </b-form-group>
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-form-group
          class="col-md-12 col-lg-12"
          :label="$t('upload.origin-data')"
          label-for="origin"
        >
          <ValidationProvider
            :name="$t('upload.origin-data')"
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              v-model="data.origin"
              :placeholder="$t('upload.origin-data')"
              :options="optionsPlataformUploading"
              label="name"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            >
              <template #no-options="{}"> Sin Resultados... </template>
            </v-select>
            <div v-if="errors[0]">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group
          v-if="type === uploadTeacherOption"
          class="col-md-12 col-lg-12"
          :label="$t('upload.upload-massive-teacher')"
          label-for="comune"
        >
          <ValidationProvider
            :name="$t('upload.upload-massive-teacher')"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-file
              browse-text=".xlsx"
              accept=".XLSX"
              class="p-2"
              v-model="data.file"
              :class="errors.length > 0 ? ' is-invalid' : ''"
              :placeholder="$t('upload.upload-massive-teacher')"
            ></b-form-file>
            <div v-if="errors[0]">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group
          v-if="type === uploadAttorneyOption"
          class="col-md-12 col-lg-12"
          :label="$t('upload.upload-massive-attorneys')"
          label-for="comune"
        >
          <ValidationProvider
            :name="$t('upload.upload-massive-attorneys')"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-file
              browse-text=".xlsx"
              accept=".XLSX"
              class="p-2"
              v-model="data.file"
              :class="errors.length > 0 ? ' is-invalid' : ''"
              :placeholder="$t('upload.upload-massive-attorneys')"
            ></b-form-file>
            <div v-if="errors[0]">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group
          v-if="type === uploadStudentOption"
          class="col-md-12 col-lg-12"
          :label="$t('upload.upload-students')"
          label-for="comune"
        >
          <ValidationProvider
            :name="$t('upload.upload-students')"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-file
              browse-text=".xlsx"
              accept=".XLSX"
              class="p-2"
              v-model="data.file"
              :class="errors.length > 0 ? ' is-invalid' : ''"
              :placeholder="$t('upload.upload-students')"
            ></b-form-file>
            <div v-if="errors[0]">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
        </b-form-group>
        <hr />
        <b-button class="ml-1 float-right" variant="success" type="submit">
          <span v-if="coursesLoading || userCollegeLoading">
            <b-spinner small type="grow"></b-spinner>
            {{ $t("app.loading") }}...
          </span>
          <span v-else>
            {{ $t("app.save") }}
          </span>
        </b-button>
        <b-button
          @click="$emit('close')"
          class="float-right"
          variant="outline-danger"
          >{{ $t("app.cancel") }}</b-button
        >
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  OPTIONS_PLATFORM_MASSIVE_UPLOAD,
  OPTION_PLATFORM_MASSIVE_UPLOAD_NETCORE_ID,
  OPTION_PLATFORM_MASSIVE_UPLOAD_GRANGE_ID,
} from "@/constants/college";
export default {
  data() {
    return {
      uploadTeacherOption: 0,
      uploadAttorneyOption: 1,
      uploadStudentOption: 2,
      data: {
        file: null,
        origin: OPTIONS_PLATFORM_MASSIVE_UPLOAD[0],
      },
      type: 0,
      optionsPlataformUploading: OPTIONS_PLATFORM_MASSIVE_UPLOAD,
    };
  },
  methods: {
    ...mapActions({
      uploadTeacher: "uploadTeacher",
      uploadStudentsToCollege: "uploadStudentsToCollege",
    }),
    async onSubmit() {
      let payload = {
        id: this.$route.params.id,
        type: this.type,
        file: this.data.file,
        origin: this.data.origin.id,
      };
      const resp =
        this.type === this.uploadStudentOption
          ? await this.uploadStudentsToCollege(payload)
          : await this.uploadTeacher(payload);
      if (resp.status === 201 || resp.status === 200) {
        this.$emit("refresh");
        await this.successUpload(resp.data.users.length);
      }
    },
    successUpload(count) {
      this.$bvModal
        .msgBoxOk(
          `${this.$t("upload.message-success")} ${count} ${this.$t(
            "user-college.users"
          )}`,
          {
            title: this.$t("upload.congratulations"),
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            okTitle: this.$t("app.close"),
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
            noCloseOnBackdrop: true,
          }
        )
        .then((value) => {
          value ? this.$emit("close") : "";
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  computed: {
    ...mapGetters({
      coursesLoading: "coursesLoading",
      userCollegeLoading: "userCollegeLoading",
    }),
  },
};
</script>
<style>
.vs__dropdown-toggle {
  border-radius: 7px !important;
  padding: 2px 5px 5px 4px !important;
}
</style>
