<template>
  <div class="">
    <p>
      {{ $t("upload.students-xlsx") }}
    </p>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-form-group
          class="col-md-12 col-lg-12"
          :label="$t('upload.origin-data')"
          label-for="comune"
        >
          <ValidationProvider
            :name="$t('upload.origin-data')"
            rules=""
            v-slot="{ errors }"
          >
            <v-select
              :disabled="true"
              v-model="data.origin"
              :placeholder="$t('upload.origin-data')"
              :options="[{ id: 1, name: 'Netcore' }]"
              label="name"
              :class="errors.length > 0 ? ' is-invalid' : ''"
            >
              <template #no-options="{}"> Sin Resultados... </template>
            </v-select>
            <div v-if="errors[0]">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
        </b-form-group>
        <b-form-group
          class="col-md-12 col-lg-12"
          :label="$t('upload.origin-data')"
          label-for="comune"
        >
          <ValidationProvider
            :name="$t('upload.upload')"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-file
              browse-text=".xlsx"
              accept=".XLSX"
              class="p-2"
              v-model="data.file"
              :class="errors.length > 0 ? ' is-invalid' : ''"
              :placeholder="$t('upload.upload-xlsx')"
            ></b-form-file>
            <!-- <b-link href="#" target="_blank" class="btn btn-success btn-sm mt-1">{{
            $t("example-xlsx")
        }}</b-link> -->
            <div v-if="errors[0]">
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
        </b-form-group>

        <hr />
        <b-button
          :disabled="coursesLoading"
          class="ml-1 float-right"
          variant="success"
          type="submit"
        >
          <span v-if="coursesLoading">
            <b-spinner small type="grow"></b-spinner>
            {{ $t("app.loading") }}...
          </span>
          <span v-else>
            {{ $t("app.save") }}
          </span>
        </b-button>
        <b-button
          @click="$emit('close')"
          class="float-right"
          variant="outline-danger"
          >{{ $t("app.cancel") }}</b-button
        >
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      data: {
        file: null,
        origin: { id: 1, name: "Netcore" },
      },
    };
  },
  methods: {
    ...mapActions({
      uploadStudents: "uploadStudents",
    }),
    async onSubmit() {
      let payload = {
        course: this.$route.params.id,
        file: this.data.file,
      };
      const resp = await this.uploadStudents(payload);
      if (resp.status === 201 || resp.status === 200) {
        await this.successUpload(resp.data.users.length);
      }
    },
    successUpload(count) {
      this.$bvModal
        .msgBoxOk(this.$t("upload.message-success") + " " + count, {
          title: this.$t("upload.congratulations"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: this.$t("app.close"),
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
          noCloseOnBackdrop: true,
        })
        .then((value) => {
          value ? this.$emit("close") : "";
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  computed: {
    ...mapGetters({
      coursesLoading: "coursesLoading",
    }),
  },
};
</script>