<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="new-user-info">
          <b-row class="justify-content-md-center">
            <b-form-group
              v-if="!data.id"
              class="col-md-12"
              :label="$t('user-college.user')"
              label-for="education"
            >
              <ValidationProvider
                :name="$t('user-college.users')"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  v-model="data.user_id"
                  :placeholder="$t('app.min-search')"
                  :options="
                    type == 0 ? getUserCollegesTeacher : getUserCollegesStudent
                  "
                  label="name"
                  @search="(query) => getDebouncedCollegeUsers(query)"
                  :class="errors.length > 0 ? ' is-invalid' : ''"
                >
                  <template #option="{ user }">
                    <p style="margin: 0">
                      {{
                        `${user.name} ${
                          user.first_lastname ? user.first_lastname : ""
                        } (${user.rut})`
                      }}
                    </p>
                  </template>
                  <template #selected-option="{ user }">
                    <p style="margin: 0">
                      {{
                        `${user.name} ${
                          user.first_lastname ? user.first_lastname : ""
                        } (${user.rut})`
                      }}
                    </p>
                  </template>
                  <template #no-options>
                    {{ $t("app.not-found") }}...
                  </template>
                </v-select>
                <div v-if="errors[0]">
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-row>
          <hr />
          <b-button
            type="submit"
            :disabled="coursesLoading"
            class="ml-1 float-right"
            variant="success"
          >
            <span v-if="coursesLoading">
              <b-spinner small type="grow"></b-spinner>
              {{ $t("app.loading") }}...
            </span>
            <span v-else>
              {{ $t("app.save") }}
            </span>
          </b-button>
          <b-button
            @click="$emit('close')"
            class="float-right"
            variant="outline-danger"
            >{{ $t("app.cancel") }}</b-button
          >
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "modalForm",
  props: {
    type: {
      type: Number,
    },
  },
  mounted() {
    core.index();
  },
  data() {
    return {
      data: {
        id: null,
        user_id: null,
      },
      users: {
        loading: false,
        debounce: null,
        debounceTime: 300,
      },
    };
  },
  methods: {
    ...mapActions({
      addUserCourses: "addUserCourses",
      fetchUserCollege: "fetchUserCollege",
      deleteUserCourses: "deleteUserCourses",
    }),
    async getDebouncedCollegeUsers(search) {
      this.users.loading = true;
      clearTimeout(this.users.debounce);
      this.users.debounce = await setTimeout(async () => {
        if (search.length >= 2) {
          this.users.loading = true;
          try {
            let paramsSearch = {
              search,
            };
            await this.fetchUserCollege(paramsSearch);
          } catch (error) {
            return errror;
          } finally {
            this.users.loading = false;
          }
        }
      }, this.users.debounceTime);
    },
    async onSubmit() {
      let payload = {
        id: this.data.id,
        user_id: this.data.user_id.id,
      };
      const resp = await this.addUserCourses({
        course: this.$route.params.id,
        data: payload,
      });
      if (resp.status === 201 || resp.status === 200) {
        this.$emit("close");
        core.showSnackbar(
          "success",
          !this.data.id
            ? this.$t("app.success-create")
            : this.$t("app.success-edit")
        );
      }
      this.reserForm();
    },
    reserForm() {
      this.data = {
        id: null,
        user_id: null,
      };
    },
  },
  computed: {
    ...mapGetters({
      coursesLoading: "coursesLoading",
      getUserCollegesTeacher: "getUserCollegesTeacher",
      getUserCollegesStudent: "getUserCollegesStudent",
    }),
  },
};
</script>
