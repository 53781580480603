<template>
  <b-container fluid>
    <div v-if="coursesLoading" class="d-flex justify-content-center mb-3 mt-5">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
        type="grow"
        variant="primary"
      ></b-spinner>
      <span class="h4 mt-2 text-primary"> {{ $t("app.loading") }}... </span>
    </div>
    <b-row v-else-if="getCoursesDetails">
      <b-col md="12">
        <!-- <b-card class="shadow"> -->
        <iq-card>
          <template v-slot:body>
            <div>
              <span class="h4">{{ getCoursesDetails.name }}</span>
              <b-button
                class="float-right"
                variant="primary"
                @click="$refs.modalUserTeacher.show({ type: 1 })"
                >{{ $t("courses.add-students") }}</b-button
              >
              <b-button
                class="mr-1 float-right"
                @click="$refs.modalUserTeacher.show({ type: 0 })"
                variant="primary"
                >{{ $t("courses.add-teacher") }}</b-button
              >
            </div>
          </template>
        </iq-card>
        <!-- </b-card> -->
      </b-col>
      <b-col md="3">
        <!-- <b-card class="ml-2 mt-3 shadow" :title=""> -->
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t("courses.details-course") }}</h4>
          </template>
          <template v-slot:body>
            <div v-if="getCoursesDetails.schoolPeriod">
              <strong
                ><i class="ri-global-line"></i> {{ $t("periods.how") }}:
              </strong>
              {{ getCoursesDetails.schoolPeriod.name }}
            </div>
            <div v-if="getCoursesDetails.schoolPeriod">
              <span>
                <strong
                  ><i class="ri-calendar-line"></i> {{ $t("periods.start") }}:
                </strong>
                {{
                  formatDate(getCoursesDetails.schoolPeriod.start_date)
                }} </span
              ><br />
              <span>
                <strong
                  ><i class="ri-calendar-line"></i> {{ $t("periods.end") }}:
                </strong>
                {{ formatDate(getCoursesDetails.schoolPeriod.end_date) }}
              </span>
            </div>

            <p v-if="getCoursesDetails.grade">
              <strong
                ><i class="ri-award-line"></i> {{ $t("periods.grade") }}:
              </strong>
              {{ getCoursesDetails.grade.name }}
            </p>
          </template>
        </iq-card>
        <!-- </b-card> -->
      </b-col>
      <b-col md="9">
        <b-row>
          <b-col md="12">
            <!-- <b-card :title="$t('courses.user-course-students')" class="shadow"> -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">
                  {{ $t("courses.user-course-students") }}
                </h4>
              </template>
              <template v-slot:body>
                <b-col md="12" class="table-responsive">
                  <b-table
                    class="table mb-0 table-borderless"
                    :items="getStudentsCourses"
                    :fields="columns"
                    :busy="
                      !Array.isArray(getStudentsCourses) ||
                      getStudentsCourses.length === 0
                        ? true
                        : false || isBusy == true
                    "
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <div v-if="isBusy">
                          <b-spinner
                            type="grow"
                            class="align-middle"
                          ></b-spinner>
                          <strong> Cargando datos...</strong>
                        </div>
                        <span class="h5" v-else>{{ $t("app.not-found") }}</span>
                      </div>
                    </template>

                    <template v-slot:cell(action)="data">
                      <b-button
                        variant=" iq-bg-danger rounded"
                        size="sm"
                        @click="deleteItem(data.item.id)"
                        ><i class="ri-delete-bin-line ml-1"></i
                      ></b-button>
                    </template>
                  </b-table>
                </b-col>
              </template>
            </iq-card>
            <!-- </b-card> -->
          </b-col>
          <b-col md="12">
            <!-- <b-card :title="$t('courses.user-course-teacher')" class="shadow"> -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">
                  {{ $t("courses.user-course-teacher") }}
                </h4>
              </template>
              <template v-slot:body>
                <b-col md="12" class="table-responsive">
                  <b-table
                    class="table mb-0 table-borderless"
                    :items="getTeacherCourses"
                    :fields="columns"
                    :busy="
                      !Array.isArray(getTeacherCourses) ||
                      getTeacherCourses.length === 0
                        ? true
                        : false || isBusy == true
                    "
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <div v-if="isBusy">
                          <b-spinner
                            type="grow"
                            class="align-middle"
                          ></b-spinner>
                          <strong> Cargando datos...</strong>
                        </div>
                        <span class="h5" v-else>{{ $t("app.not-found") }}</span>
                      </div>
                    </template>

                    <template v-slot:cell(action)="data">
                      <b-button
                        variant=" iq-bg-danger rounded"
                        size="sm"
                        @click="deleteItem(data.item.id)"
                        ><i class="ri-delete-bin-line ml-1"></i
                      ></b-button>
                    </template>
                  </b-table>
                </b-col>
              </template>
            </iq-card>
            <!-- </b-card> -->
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <modal-form ref="modalUserTeacher" />
  </b-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import modalForm from "@/components/Modal/MassiveLoad/MassiveLoad.vue";
import { core } from "@/config/pluginInit";
import moment from "moment";
export default {
  components: {
    modalForm,
  },
  mounted() {
    this.showCourses(this.$route.params.id);
  },
  data() {
    return {
      isBusy: false,
    };
  },
  methods: {
    ...mapActions({
      showCourses: "showCourses",
      deleteUserCourses: "deleteUserCourses",
    }),
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t("app.body-confirm-delete"), {
          title: this.$t("app.title-confirm-delete"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success rounded",
          cancelVariant: "outline-danger rounded",
          cancelVariant: "light",
          okTitle: this.$t("app.yes"),
          cancelTitle: this.$t("app.not"),
          headerClass: "text-center",
          bodyClass: "text-center text-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.deleteUserCourses({
              course: this.$route.params.id,
              user: id,
            });
            if (resp.status == 200) {
              core.showSnackbar("success", resp.data.message);
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
  computed: {
    ...mapGetters({
      getCoursesDetails: "getCoursesDetails",
      coursesLoading: "coursesLoading",
      getStudentsCourses: "getStudentsCourses",
      getTeacherCourses: "getTeacherCourses",
    }),

    columns() {
      return [
        {
          label: this.$t("profile.rut"),
          key: "user.rut",
          class: "text-center",
        },
        { label: this.$t("profile.name"), key: "name", class: "text-center" },
        {
          label: this.$t("profile.email"),
          key: "user.email",
          class: "text-center",
        },
        {
          label: this.$t("profile.phone"),
          key: "user.phone",
          class: "text-center",
        },
        { label: this.$t("app.action"), key: "action", class: "text-center" },
      ];
    },
  },
};
</script>
